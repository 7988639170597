/* Inter Regular */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
}

/* Inter Medium */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Inter Semi-Bold */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Inter Bold */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Inter Extra-Bold */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
}

/* Inter Black */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Inter Light */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Inter Extra-Light */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* Inter Thin */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Inter Variable (Optional) */
@font-face {
  font-family: "Inter";
  src: url("/public/fonts/Inter/Inter-Variable.ttf")
    format("truetype-variations");
  font-weight: 100 900;
  font-style: normal oblique;
}

@font-face {
  font-family: "Century Gothic";
  src: url("/public/fonts/CenturyGothic/CenturyGothic-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Century Gothic";
  src: url("/public/fonts/CenturyGothic/CenturyGothic-Bold.ttf")
    format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("/public/fonts/Calibri/Calibri-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("/public/fonts/Calibri/Calibri-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Calibri";
  src: url("/public/fonts/Calibri/Calibri-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Calibri";
  src: url("/public/fonts/Candara/Candara-Bold-Italic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Candara";
  src: url("/public/fonts/Candara/Candara-Regular.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Candara";
  src: url("/public/fonts/Candara/Candara-Italic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Candara";
  src: url("/public/fonts/Candara/Candara-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Candara";
  src: url("/public/fonts/Candara/Candara-Bold-Italic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/public/fonts/SegoeUI/SegoeUI-Light.ttf") format("truetype");
  font-weight: 300;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/public/fonts/SegoeUI/SegoeUI-SemiLight.ttf") format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/public/fonts/SegoeUI/SegoeUI-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Segoe UI";
  src: url("/public/fonts/SegoeUI/SegoeUI-SemiBold.ttf") format("truetype");
  font-weight: 600;
}

@font-face {
  font-family: "Segoe UI";
  src: url("/public/fonts/SegoeUI/SegoeUI-Bold.ttf") format("truetype");
  font-weight: 700;
}