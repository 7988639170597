@tailwind base;
@tailwind components;
@tailwind utilities;

body, html {
  overscroll-behavior: none;
}

.h-screen {
  height: 100dvh;
}

:root {
  font-family: "Inter";
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

#root, #app-container {
  /*
    Setting isolate on the root element prevents z-indexes from causing normal components to
    appear above components in Radix UI portals
  */
  isolation: isolate;
}

#admin-bar {
  flex-shrink: 0;
  width: 100%;
}

:root {
  --screen-height: 100dvh;
}

.h-screen {
  height: var(--screen-height);
}


@layer base {
  :root {
    --light-background: #fff;
    --dark-background: #2c2c2c;

    --light-background-selected: #f0f0f0;
    --dark-background-selected: rgba(54, 54, 54, 0.96);

    --light-background-secondary: #f6f9ff;
    --dark-background-secondary: #1f1f1f;

    --light-background-subtle: #f8f8f8;
    --dark-background-subtle: #212121;

    --light-background-tertiary: #E9EBF1;
    --dark-background-tertiary: #141414;

    --light-foreground: #161616;
    --dark-foreground: #fff;

    --overlay: 0, 0, 0, 0.2;
    --light-blocker: 255, 255, 255, 0.9;
    --dark-blocker: 44, 44, 44, 0.9;

    --light-popover: var(--light-background);
    --dark-popover: #161616;

    --light-popover-foreground: var(--light-foreground);
    --dark-popover-foreground: var(--dark-foreground);

    --light-popover-selected: #f4f4f4;
    --dark-popover-selected: #2c2c2c;

    --card: 0 0% 100%;
    --card-foreground: 222.2 84% 4.9%;

    --light-border: #dadada;
    --dark-border: #525252;

    --light-border-focused: #989898;
    --dark-border-focused: #989898;

    --input: 214.3 31.8% 91.4%;

    --light-primary: #0868f7;
    --dark-primary: #82b4ff;
    --primary-hover: #8bb2f5;

    --primary-foreground: #fff;

    --light-secondary: #989898;
    --dark-secondary: #989898;

    --light-tertiary: #022749;
    --dark-tertiary: #BFDCFF;

    --secondary-foreground: 222.2 47.4% 11.2%;

    --light-accent: #f6f6f6;
    --dark-accent: #1f1f1f;

    --light-accent-focused: var(--light-popover);
    --dark-accent-focused: var(--dark-popover);

    --accent-hover: var(--accent-focused);
    --accent-foreground: var(--foreground);

    --light-placeholder: #b5b5b5;
    --dark-placeholder: #727272;

    --light-highlight: #f4f7fe;
    --dark-highlight: #282930;

    --light-comment: #fff2e6;
    --light-comment-underline: #ffd9b0;
    --light-comment-two: #ffe7cc;
    --light-comment-two-underline: #f4d7bb;
    --light-comment-selected: #fff2e6;
    --light-comment-selected-underline: #f1a155;
    --light-comment-odo: #e9f2ff;
    --light-comment-odo-underline: #e9f2ff;
    --light-comment-odo-selected: #e9f2ff;
    --light-comment-odo-selected-underline: #0868f7;

    --dark-comment: #4d4425;
    --dark-comment-underline: #393315;
    --dark-comment-two: #594f37;
    --dark-comment-two-underline: #705b28;
    --dark-comment-selected: #4d4425;
    --dark-comment-selected-underline: #867635;
    --dark-comment-odo: #3c4a67;
    --dark-comment-odo-underline: #2a3849;
    --dark-comment-odo-selected: #3c4a67;
    --dark-comment-odo-selected-underline: #82b4ff;

    --light-code-background: #ececec;
    --dark-code-background: #101010;

    --additive: #36a934;
    --warning: orange;
    --destructive: #df3c3c;

    --ring: 215 20.2% 65.1%;

    --radius: 0.5rem;
  }
}

:root {
  /* Sizes */
  --space-scroll-bar: 18px;
  --space-xs: 4px;
  --space-sm: 8px;
  --space-md: 12px;
  --space-2m: 16px;
  --space-lg: 24px;
  --space-xl: 32px;
  --space-2xl: 36px;
  --space-3xl: 44px;
  --space-4xl: 60px;
  --space-4xl: 80px;

  --text-2xs: 9px;
  --text-xs: 12px;
  --text-sm: 14px;
  font-size: 16px;
  --text-md: 16px;
  --text-lg: 18px;
  --text-xl: 20px;
  --text-2xl: 24px;
  --text-3xl: 32px;

  /* Colors */
  --background: var(--light-background);
  --background-selected: var(--light-background-selected);
  --background-secondary: var(--light-background-secondary);
  --background-tertiary: var(--light-background-tertiary);
  --background-subtle: var(--light-background-subtle);
  --foreground: var(--light-foreground);
  --primary: var(--light-primary);
  --secondary: var(--light-secondary);
  --tertiary: var(--light-tertiary);
  --popover: var(--light-popover);
  --popover-foreground: var(--light-popover-foreground);
  --popover-selected: var(--light-popover-selected);
  --border: var(--light-border);
  --border-focused: var(--light-border-focused);
  --accent: var(--light-accent);
  --accent-focused: var(--light-accent-focused);
  --code-background: var(--light-code-background);
  --placeholder: var(--light-placeholder);
  --blocker: var(--light-blocker);
  --highlight: var(--light-highlight);

  /* Comments */
  --comment: var(--light-comment);
  --comment-underline: var(--light-comment-underline);
  --comment-two: var(--light-comment-two);
  --comment-two-underline: var(--light-comment-two-underline);
  --comment-selected: var(--light-comment-selected);
  --comment-selected-underline: var(--light-comment-selected-underline);

  --comment-odo: var(--light-comment-odo);
  --comment-odo-underline: var(--light-comment-odo);
  --comment-odo-selected: var(--light-comment-odo-selected);
  --comment-odo-selected-underline: var(--light-comment-odo-selected-underline);

  --background-lighter: color-mix(in srgb, var(--background) 90%, white);
  --background-darker: color-mix(in srgb, var(--background) 90%, black);

  --border-lighter: color-mix(in srgb, var(--border) 80%, white);
  --border-darker: color-mix(in srgb, var(--border) 90%, black);
  
  --background-selected-lighter: color-mix(in srgb, var(--background-selected) 90%, white);
  --background-selected-darker: color-mix(in srgb, var(--background-selected) 90%, black);
  
  --background-secondary-lighter: color-mix(in srgb, var(--background-secondary) 90%, white);
  --background-secondary-darker: color-mix(in srgb, var(--background-secondary) 90%, black);

  --background-tertiary-lighter: color-mix(in srgb, var(--background-tertiary) 90%, white);
  --background-tertiary-darker: color-mix(in srgb, var(--background-tertiary) 90%, black);
  
  --background-subtle-lighter: color-mix(in srgb, var(--background-subtle) 90%, white);
  --background-subtle-darker: color-mix(in srgb, var(--background-subtle) 90%, black);
  
  --foreground-lighter: color-mix(in srgb, var(--foreground) 90%, white);
  --foreground-darker: color-mix(in srgb, var(--foreground) 90%, black);
  
  --primary-lighter: color-mix(in srgb, var(--primary) 90%, white);
  --primary-darker: color-mix(in srgb, var(--primary) 80%, black);
  
  --secondary-lighter: color-mix(in srgb, var(--secondary) 90%, white);
  --secondary-darker: color-mix(in srgb, var(--secondary) 90%, black);
  
  --tertiary-lighter: color-mix(in srgb, var(--tertiary) 90%, white);
  --tertiary-darker: color-mix(in srgb, var(--tertiary) 90%, black);
  
  --accent-lighter: color-mix(in srgb, var(--accent) 90%, white);
  --accent-darker: color-mix(in srgb, var(--accent) 90%, black);
  
  --accent-focused-lighter: color-mix(in srgb, var(--accent-focused) 90%, white);
  --accent-focused-darker: color-mix(in srgb, var(--accent-focused) 90%, black);
  
  --code-background-lighter: color-mix(in srgb, var(--code-background) 90%, white);
  --code-background-darker: color-mix(in srgb, var(--code-background) 90%, black);
  
  --placeholder-lighter: color-mix(in srgb, var(--placeholder) 90%, white);
  --placeholder-darker: color-mix(in srgb, var(--placeholder) 90%, black);
  
  --highlight-lighter: color-mix(in srgb, var(--highlight) 90%, white);
  --highlight-darker: color-mix(in srgb, var(--highlight) 90%, black);
  
  --comment-lighter: color-mix(in srgb, var(--comment) 90%, white);
  --comment-darker: color-mix(in srgb, var(--comment) 90%, black);
  
  --comment-two-lighter: color-mix(in srgb, var(--comment-two) 90%, white);
  --comment-two-darker: color-mix(in srgb, var(--comment-two) 90%, black);
  
  --comment-selected-lighter: color-mix(in srgb, var(--comment-selected) 90%, white);
  --comment-selected-darker: color-mix(in srgb, var(--comment-selected) 90%, black);
  
  --comment-odo-lighter: color-mix(in srgb, var(--comment-odo) 90%, white);
  --comment-odo-darker: color-mix(in srgb, var(--comment-odo) 90%, black);

  --additive-lighter: color-mix(in srgb, var(--additive) 90%, white);
  --additive-darker: color-mix(in srgb, var(--additive) 90%, black);

  --warning-lighter: color-mix(in srgb, var(--warning) 90%, white);
  --warning-darker: color-mix(in srgb, var(--warning) 90%, black);

  --destructive-lighter: color-mix(in srgb, var(--destructive) 90%, white);
  --destructive-darker: color-mix(in srgb, var(--destructive) 90%, black);
}

@media (max-width: 640px) {
  :root {
    /* Sizes */
    --space-xs: 4px;
    --space-sm: 8px;
    --space-md: 10px;
    --space-2m: 12px;
    --space-lg: 20px;
    --space-xl: 24px;
    --space-2xl: 28px;
    --space-3xl: 36px;
    --space-4xl: 52px;

    --text-2xs: 8px;
    --text-xs: 10px;
    --text-sm: 12px;
    font-size: 14px;
    --text-md: 14px;
    --text-lg: 16px;
    --text-xl: 17px;
    --text-2xl: 19px;
    --text-3xl: 24px;
  }
}

.admin, .admin * {
  --primary: #f78708;
  --primary-lighter: color-mix(in srgb, var(--primary) 90%, white);
  --primary-darker: color-mix(in srgb, var(--primary) 80%, black);
}

.hide-admin .admin {
  display: none !important;
}

.disable-admin, .disable-admin * {
  --primary: var(--light-primary) !important;
  --primary-lighter: color-mix(in srgb, var(--primary) 90%, white);
  --primary-darker: color-mix(in srgb, var(--primary) 80%, black);
}

@media (prefers-color-scheme: dark) {
  :root {
    --background: var(--dark-background);
    --background-selected: var(--dark-background-selected);
    --background-secondary: var(--dark-background-secondary);
    --background-tertiary: var(--dark-background-tertiary);
    --background-subtle: var(--dark-background-subtle);
    --foreground: var(--dark-foreground);
    --primary: var(--dark-primary);
    --secondary: var(--dark-secondary);
    --tertiary: var(--dark-tertiary);
    --popover: var(--dark-popover);
    --popover-foreground: var(--dark-popover-foreground);
    --popover-selected: var(--dark-popover-selected);
    --border: var(--dark-border);
    --border-focused: var(--dark-border-focused);
    --accent: var(--dark-accent);
    --accent-focused: var(--dark-accent-focused);
    --code-background: var(--dark-code-background);
    --placeholder: var(--dark-placeholder);
    --blocker: var(--dark-blocker);
    --highlight: var(--dark-highlight);

    /* Comments */
    --comment: var(--dark-comment);
    --comment-underline: var(--dark-comment-underline);
    --comment-two: var(--dark-comment-two);
    --comment-two-underline: var(--dark-comment-two-underline);
    --comment-selected: var(--dark-comment-selected);
    --comment-selected-underline: var(--dark-comment-selected-underline);

    --comment-odo: var(--dark-comment-odo);
    --comment-odo-underline: var(--dark-comment-odo);
    --comment-odo-selected: var(--dark-comment-odo-selected);
    --comment-odo-selected-underline: var(
      --dark-comment-odo-selected-underline
    );

    --background-lighter: color-mix(in srgb, var(--background) 90%, white);
    --background-darker: color-mix(in srgb, var(--background) 90%, black);

    --border-lighter: color-mix(in srgb, var(--border) 90%, white);
    --border-darker: color-mix(in srgb, var(--border) 90%, black);
    
    --background-selected-lighter: color-mix(in srgb, var(--background-selected) 90%, white);
    --background-selected-darker: color-mix(in srgb, var(--background-selected) 90%, black);
    
    --background-secondary-lighter: color-mix(in srgb, var(--background-secondary) 90%, white);
    --background-secondary-darker: color-mix(in srgb, var(--background-secondary) 90%, black);

    --background-tertiary-lighter: color-mix(in srgb, var(--background-tertiary) 90%, white);
    --background-tertiary-darker: color-mix(in srgb, var(--background-tertiary) 90%, black);
    
    --background-subtle-lighter: color-mix(in srgb, var(--background-subtle) 90%, white);
    --background-subtle-darker: color-mix(in srgb, var(--background-subtle) 90%, black);
    
    --foreground-lighter: color-mix(in srgb, var(--foreground) 90%, white);
    --foreground-darker: color-mix(in srgb, var(--foreground) 90%, black);
    
    --primary-lighter: color-mix(in srgb, var(--primary) 90%, white);
    --primary-darker: color-mix(in srgb, var(--primary) 80%, black);
    
    --secondary-lighter: color-mix(in srgb, var(--secondary) 90%, white);
    --secondary-darker: color-mix(in srgb, var(--secondary) 90%, black);
    
    --tertiary-lighter: color-mix(in srgb, var(--tertiary) 90%, white);
    --tertiary-darker: color-mix(in srgb, var(--tertiary) 90%, black);
    
    --accent-lighter: color-mix(in srgb, var(--accent) 90%, white);
    --accent-darker: color-mix(in srgb, var(--accent) 90%, black);
    
    --accent-focused-lighter: color-mix(in srgb, var(--accent-focused) 90%, white);
    --accent-focused-darker: color-mix(in srgb, var(--accent-focused) 90%, black);
    
    --code-background-lighter: color-mix(in srgb, var(--code-background) 90%, white);
    --code-background-darker: color-mix(in srgb, var(--code-background) 90%, black);
    
    --placeholder-lighter: color-mix(in srgb, var(--placeholder) 90%, white);
    --placeholder-darker: color-mix(in srgb, var(--placeholder) 90%, black);
    
    --highlight-lighter: color-mix(in srgb, var(--highlight) 90%, white);
    --highlight-darker: color-mix(in srgb, var(--highlight) 90%, black);
    
    --comment-lighter: color-mix(in srgb, var(--comment) 90%, white);
    --comment-darker: color-mix(in srgb, var(--comment) 90%, black);
    
    --comment-two-lighter: color-mix(in srgb, var(--comment-two) 90%, white);
    --comment-two-darker: color-mix(in srgb, var(--comment-two) 90%, black);
    
    --comment-selected-lighter: color-mix(in srgb, var(--comment-selected) 90%, white);
    --comment-selected-darker: color-mix(in srgb, var(--comment-selected) 90%, black);
    
    --comment-odo-lighter: color-mix(in srgb, var(--comment-odo) 90%, white);
    --comment-odo-darker: color-mix(in srgb, var(--comment-odo) 90%, black);

    --additive-lighter: color-mix(in srgb, var(--additive) 90%, white);
    --additive-darker: color-mix(in srgb, var(--additive) 90%, black);

    --warning-lighter: color-mix(in srgb, var(--warning) 90%, white);
    --warning-darker: color-mix(in srgb, var(--warning) 90%, black);

    --destructive-lighter: color-mix(in srgb, var(--destructive) 90%, white);
    --destructive-darker: color-mix(in srgb, var(--destructive) 90%, black);
  }

  .admin, .admin * {
    --primary: #ffba82;
    --primary-lighter: color-mix(in srgb, var(--primary) 90%, white);
    --primary-darker: color-mix(in srgb, var(--primary) 80%, black);
  }

  .disable-admin, .disable-admin * {
    --primary: var(--dark-primary) !important;
    --primary-lighter: color-mix(in srgb, var(--primary) 90%, white);
    --primary-darker: color-mix(in srgb, var(--primary) 80%, black);
  }
}

@layer base {
  * {
    @apply border-border;
  }
  body {
    @apply bg-background text-foreground;
  }
}

@layer components {
  .accent {
    @apply bg-accent hover:bg-accent-hover focus:bg-accent-focused text-accent-foreground;
  }
  .popover {
    @apply bg-popover text-popover-foreground border rounded drop-shadow;
  }

  .vertical-text-elipsis {
    display: -webkit-box;
    overflow: hidden;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .slate-listStyleType-unchecked {
    list-style-type: none !important;
  }

  .outline-primary {
    outline-color: var(--primary) !important;
  }

  .outline-foreground {
    outline-color: var(--foreground) !important;
  }

  .hide-text-selection *::selection {
    background-color: transparent;
  }

  .link {
    @apply text-primary;
    @apply underline;
  }

  .transitioning-comment {
    @apply transition-[top,left,right] duration-500;
  }
}

[placeholder]:empty::before,
[placeholder]:has(br:only-child)::before {
  content: attr(placeholder);
  position: absolute;
  @apply text-placeholder;
}

[data-persistent-placeholder="true"]::after {
  content: attr(placeholder);
  @apply text-placeholder;
}

::-webkit-scrollbar {
  width: var(--space-scroll-bar);
}

::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 4px solid var(--background);
  @apply bg-border;
}

.white-scrollbar ::-webkit-scrollbar-thumb {
  opacity: 20%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 5px solid var(--border);
}

.bg-background-secondary ::-webkit-scrollbar-thumb {
  opacity: 20%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 5px solid var(--background-secondary);
}

.bg-background-tertiary ::-webkit-scrollbar-thumb {
  opacity: 20%;
  background-color: rgba(0, 0, 0, 0.2);
  border: 5px solid var(--background-tertiary);
}

.bg-accent::-webkit-scrollbar-thumb {
  border-radius: 100px;
  border: 4px solid var(--accent);
  @apply bg-border;
}

::-webkit-scrollbar-corner {
  @apply bg-background;
}

* {
  outline: none;
}

details > summary.list-none::-webkit-details-marker,
details > summary.list-none::marker {
  display: none;
}

.react-datepicker-wrapper {
  display: flex !important;
}

.react-datepicker * {
  color: var(--foreground) !important;
}

.react-datepicker {
  background-color: var(--background) !important;
}

.react-datepicker__tab-loop {
  position: absolute;
}

.react-datepicker__header {
  background-color: var(--accent) !important;
}

.react-datepicker__day--selected {
  background-color: var(--primary) !important;
  color: var(--background) !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
  border-radius: 0.3rem;
  background-color: var(--background-selected) !important;
  color: var(--foreground) !important;
}